// wiredep-removed:bower:less
// wiredep-removed:endbower

@import "bootstrap/bootstrap";
@import "bootstrap/_variables";

$border-color : rgba(0,0,0,0.1);

// Base
@import "base";

// Pages
@import "pages/login";
@import "pages/dashboard";
@import "pages/home";
@import "pages/inbox";
@import "pages/compose";
@import "pages/profile";
@import "pages/invoice";
@import "pages/404";
@import "pages/docs";

//Layout
@import "layout/sidebar/sidebar";
@import "layout/topnav/topnav";
@import "layout/utils";

// Widgets and Effects
@import "widgets/input-underline";
@import "widgets/calendar";
@import "widgets/stats";
@import "widgets/buttons";
@import "widgets/input-type";
@import "widgets/carousels";
@import "widgets/growl";
@import "widgets/switch";
@import "widgets/todo";
@import "widgets/map";
@import "widgets/progressButton";
@import "widgets/checkbox";
//@import "../../bower_components/bootstrap-btn-outline-rounded/index";
@import "transitions/all";
// custom css
@import "pages/custom";


// Other
.browsehappy {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

.rtl {
	* {
		direction: rtl;
	}
	#body-container {
		margin-right: 235px;
		margin-left: 0;
	}
	img {
		margin-left: 5px;
	}
	.fa-search {
		margin-left: -27px;
    	margin-right: 20px;
	}
	.navbar-brand {
		float: right;
	}
	.side-widgets {
		margin-left: 0 !important;
	}
	#sidebar {
		right: 0;
		div.side-menu .menu-body ul.sidenav li ul.nested-dropdown {
		left: 0;
		right: 45px;
		}
	}
	.radio label, .checkbox label {
		padding-left: 0;
		padding-right: 20px;
	}
	.checkbox input[type="checkbox"], .radio input[type="radio"] {
		margin-left: 0;
		margin-right: -20px;
	}
	ul.navbar-right {
		padding-right: 0;
	}	
	.navbar-form {
		margin-left: 10px;
	}
	ul.navbar-nav {
		padding-right: 0;
		margin-right: 0;
		float: right;
	}
	.topnav-navbar ul.pull-right.navbar-nav {
		margin-right: 0;
	}
	.fc-toolbar{
		padding-left: 10px;
	}
}

* {
	a:hover {
		cursor: pointer;
	}
}

.animation {
    -webkit-animation: cssAnimation 0.45s 1 ease;
    -moz-animation: cssAnimation 0.45s 1 ease;
    -o-animation: cssAnimation 0.45s 1 ease;
}
@-webkit-keyframes cssAnimation {
    from {
        -webkit-transform: rotate(0deg) scale(0.890) skew(0deg) translate(0px);
        opacity: 0.3;
    }
    to {
        -webkit-transform: rotate(0deg) scale(1) skew(0deg) translate(0px);
        opacity: 1;
    }
}
@-moz-keyframes cssAnimation {
    from {
        -moz-transform: rotate(0deg) scale(0.890) skew(0deg) translate(0px);
        opacity: 0.3;
    }
    to {
        -moz-transform: rotate(0deg) scale(1) skew(0deg) translate(0px);
        opacity: 1;
    }
}
@-o-keyframes cssAnimation {
    from {
        -o-transform: rotate(0deg) scale(0.890) skew(0deg) translate(0px);
        opacity: 0.3;
    }
    to {
        -o-transform: rotate(0deg) scale(1) skew(0deg) translate(0px);
        opacity: 1;
    }
}
.bg-white {
	background: #fff;
}
.topnav-navbar {
	padding: 0;
}
.navbar-brand {
	display: inline-block!important;
    padding: 10px 34px;
    height: 50px;
    margin: 0 auto;
    width: 235px;
    background: $brand-primary;
}
.nav-lists {
	height: 50px;
}


@media only screen and (min-width: 992px) {
	.pr0 {
		padding-right: 0px;
	}
}
@media only screen and (max-width: 767px) {
	.navbar-brand {
		float: none;
	}
	.navbar-toggleable-xs {
		float: right;
		display: inline-block!important;
	}
}