#inputSuccess1 {
	border-color: $brand-success;
}
#inputWarning1 {
	border-color: $brand-warning;
}
#inputError1 {
	border-color: $brand-danger;
}
label.control-label.col-sm-2.sucesss {
  color: $brand-success;
}
label.control-label.col-sm-2.warnings {
  color: $brand-warning;
}
label.control-label.col-sm-2.errors {
  color: $brand-danger;
}


