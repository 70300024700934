/* Have to set height explicity on ui-view 
to prevent collapsing during animation*/
/*.well[ui-view]{
 height: 65px; 
}*/

[ui-view] {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  -webkit-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
}

.ui-view-container {
  position: relative;
}

[ui-view].ng-enter, [ui-view].ng-leave {
  position: absolute;
  left: 0;
  right: 0;
  -webkit-transition:all .5s ease-in-out;
    -moz-transition:all .5s ease-in-out;
    -o-transition:all .5s ease-in-out;
    transition:all .5s ease-in-out;
}

[ui-view].ng-enter {
  opacity: 0;
  -webkit-transform:scale3d(0.94, 0.94, 0.94);
  -moz-transform:scale3d(0.94, 0.94, 0.94);
  transform:scale3d(0.94, 0.94, 0.94);
}

[ui-view].ng-enter-active {
  opacity: 1;
  -webkit-transform:scale3d(1, 1, 1);
  -moz-transform:scale3d(1, 1, 1);
  transform:scale3d(1, 1, 1);
}

[ui-view].ng-leave {
  opacity: 1; 
  -webkit-transform:translate3d(0, 0, 0);
  -moz-transform:translate3d(0, 0, 0);
  transform:translate3d(0, 0, 0);
}

[ui-view].ng-leave-active {
  opacity: 0;
  -webkit-transform:translate3d(0, 0, 0);
  -moz-transform:translate3d(0, 0, 0);
  transform:translate3d(0, 0, 0);
}

.transition {
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.transition-slow {
    -webkit-transition: all 0.2s-slow ease-in-out;
    -moz-transition: all 0.2s-slow ease-in-out;
    -o-transition: all 0.2s-slow ease-in-out;
    transition: all 0.2s-slow ease-in-out;
}