.ui-base {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.user-avatar {
	width: 125px;
	height: 125px;
}
