* {
	&:focus {
		outline: 0 !important;
	}
	ul {
		list-style-type: none;
	}
	a {
		&:hover {
			text-decoration: none;
		}
	}
}
.navbar-theme {
	padding: 0!important;
	.navbar-brand {
		color: #fff;
	}
}
.navbar-nav {
    .nav-item {
    	margin-left: 0!important;
    	.nav-link-1st {
    		background: #E7E7E7;
    		&:hover {
    			background: #E7E7E7!important;
    		}
    	}
    	.nav-link-2nd {
    		background: #1E8449;
    		&:hover {
    			background: $brand-primary !important;
    		}
    	}
    	.nav-link-3rd {
    		background: transparent;
    		color: $brand-primary;
    		&:hover {
    			background: $brand-primary !important;
    			color: lighten($brand-primary, 30%);
    		}
    		&:active {
    			background: $brand-primary !important;
    			color: lighten($brand-primary, 30%)!important;
    		}
    		&:focus {
    			background: $brand-primary !important;
    			color: lighten($brand-primary, 30%)!important;
    		}
    	}
	    .nav-link {
	      padding: 14px 16px;
	      &:hover {
	      	background: $brand-primary;
	      }
    	}
   }
}

.navbar {
	.dropdown-menu {
		margin-top: 0;
	}
}

.topnav-navbar {
	height: 50px;
	background: none;
	border-radius: 0;
	border: none;
	border-bottom: solid 1px rgba(0, 0, 0, 0.1);
	.collapse.navbar-collapse {
		background-color: #fff;
	}
	.navbar-header {
		width: $nav-width;
		background: $brand-primary;
	}
	.navbar-brand {
		color: lighten($brand-primary, 35%);
		display: block;
		&:hover {
			color: lighten($brand-primary, 40%);
		}
	}
	.dropdown-toggle::after {
		display: none;
	}
	ul.navbar-nav {
		li {
			@extend .transition;
			a {
				.fa {
					font-size: 16px;
				}
				.label {
					font-size: 9px;
					position: absolute;
					top: 9px;
					right: 7px;
					padding: 4px;
					min-width: 16px;
					font-weight: 400;
					line-height: 1;
					border-radius: 50%;
				}
				.label-success {
					background: $brand-secondary;
				}
				.label-danger {
					background: $brand-danger;
				}
				&:focus, &:hover, &:active, &.active, &.open{
					background: $brand-primary;
					color: #FFF;
					.hidden-message {
						display: inline-block;
					}
				}
			}
			.dropdown-menu {
				padding: 0;
				border: none;				
				border-top: 1px solid #eee;
				transition: all 0.1s linear;
				left: auto;
				right: 0;
				.messages-top {
					padding-left: 0px;
					padding-top: 10px;
					padding-bottom: 10px;
					border: 1px solid #eee;
					border-top: none;
					font-size: 12px;
					opacity: 0.7;
				}
				.dropdown-messages {
					min-width: 130px!important;
					height: 53px;
					border: 1px solid #eee;
					border-top: none;
					a {
						width: 100%;
						height: 53px;
						.message-sender {
							padding-top: 5px;
							font-weight: 600;
							font-size: 13px;
						}
						.message-header {
							font-size: 11px;
							padding-top: 3px;
							opacity: .7;
						}
					}
				}
				.notification-header {
					padding-left: 8px;
					padding-top: 10px;
					padding-bottom: 10px;
					border: 1px solid #eee;;
					border-top: none;
					font-size: 12px;
					opacity: 0.7;
				}
				.dropdown-notifications {
					min-width: 130px!important;
					height: 37px;
					border: 1px solid #eee;;
					border-top: none;
					a {
						width: 100%;
						height: 37px;
						.notification {
							padding-top: 5px;
							font-size: 12px;
							.fa {
								margin-right: 20px;
								opacity: 0.8;
							}
						}
					}	
				}
			}
			&.open {
				a {
					&:focus, &:hover, &:active, &.active {
						background:$brand-primary;
						color: lighten($brand-primary, 40%);
						.hidden-message {
							display: inline-block;
						}
					}
				}
			}
		}
	}
	.navbar-form {
		padding-left: 15px;
		.fa {
			opacity: 0.7;
		}
		.form-control-topnav {
			margin-left: -18px!important;
    		margin-right: 7px;
		}
		.form-control {
			width: 40px;
			border-radius: 0;
			padding: 8px 22px;
			border: none;
			margin-left: 0px;
			border-bottom: 1px solid lighten($brand-primary, 10%);
			background: inherit;
			box-shadow: none;
			opacity: 0.7;
			-webkit-transition: all .2s linear;
			-moz-transition: all .2s linear;
			-ms-transition: all .2s linear;
			-o-transition: all .2s linear;
			transition: all .2s linear;
			&:focus {
				width: 200px;
				opacity: 1;	
			}
		}
	}
	.lang {
		.dropdown-item {
			height: 35px;
			padding-top: 6px;
		}
	}	
	.admin-section {
		.dropdown {
			&.admin-dropdown, &.color-picker {
				a {
				padding-top: 10px;
				padding-bottom: 10px;
					img.topnav-img {
						width: 30px;
						margin-right: 5px;
					}
				}
			}
		}
		ul.dropdown-menu {	
			li {
				border: 1px solid #eee;
				border-top: none;
			}
		}
		&.navbar-nav {
			margin-right: 10px;
		}
	}
	.color-picker {
		a {
			i { 
				font-size: 24px!important;
	  			line-height: 19px;
	  			color: $brand-primary;
	  		}
			&:hover, &:active, &:focus {
				
				i {
					color: #FFF;
				}
			}
		}
		div.dropdown-menu {
			border-top: none;
			top: 49px;
			border-top: none !important;
			a {
				&:hover {
					background: none;
					color: inherit;
				}
			}
		}
	}
	table.color-swatches-table {
		margin-bottom: 0;
		i { 
			font-size: 24px!important;
  			line-height: 30px;
  		}
		a {
			padding: 0 !important;
			display: block;
			&:hover, &:focus {
				color: inherit;
				background: inherit;
			}
			.blue-base {
				color: #3CA2E0;
			}
			.red-base {
				color: #D24D57;
			}
			.green-base {
				color: #27ae60;
			}
			.purple-base {
				color: #957BBD;
			}
			.midnight-blue-base {
				color: #2c3e50;
			}
			.lynch-base {
				color: #6C7A89;
			}
		}
		td.colorr {
			border: 1px solid #eee;
		}
	}
}
.ureveal {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  overflow: hidden;
}
.hidd {
	display: none;
}

@media only screen and (max-width: 856px) {
	.buy-button {
		display: none;
	}
}

@media only screen and (max-width: 767px) { 
	.topnav-navbar {
		background: $brand-primary!important;
		.navbar-toggler {
			color: #fff;
			margin-top: 5px;
		}
	}
	.navbar-toggleable-xs {
		max-height: 50px;
	}
	ul.hidd {
		display: block;
		position: absolute;
		right: 0;
		top: -6px;
		z-index: 3;
		li.dropdown {
			a {
				padding-bottom: 8px !important;
			}
		}
	}
	.topnav-navbar .pull-xs-right .dropdown.admin-dropdown a img.topnav-img {
		margin-right: 0;
	}
	.navbar-nav .open .dropdown-menu {
  		position: absolute;
  		right: 0;
  		left: auto;
  	}
  	.topnav-navbar .navbar-header {
  		width: 100%;
  	}
  	.topnav-navbar .navbar-nav .dropdown-menu {
  		background-color: #fff;
  	}
  	
}

@media only screen and (min-width: 768px) and (max-width: 1024px){
.navbar-form .form-control {
	  max-width: 120px; 
	}
}
