.m-t {
	margin-top: 15px;
}
.padder {
	padding: 15px;
}
.t-p {
	padding-top: 15px;
}
.m-l {
	margin-left: 15px;
}
.p1025 {
	padding: 10px 25px;
}
.no-margin {
	margin: 0;
}