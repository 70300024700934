body {
  background-color: #ecf0f1;
}
.dashboard-page {
  background: #ecf0f1;
  color: #666;
  position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: all 0.2s ease-in-out;

  @extend .transition;

    /*
     * Base structure
     */


    /*
     * Global add-ons
     */

    .sub-header {
      padding-bottom: 10px;
      border-bottom: 1px solid #eee;
    }

    /*
     * Top navigation
     * Hide default border to remove 1px line.
     */
    .navbar-fixed-top {
      border: 0;
    }

    /*
     * Sidebar
     */

    /* Hide for mobile, show later */
    .sidebar {

      .user-avatar {
      	margin: 10px 0 10px 0;
      }

      .brand {
      	padding-top: 15px;
      }

      display: none;

      color: #FFF;

      h2 {
      	font-weight: 300;
      	small {
      		color: rgba(255, 255, 255, 0.7);
      	}
      }
    }
    @media (min-width: 768px) {
      .sidebar {
        position: fixed;
        top: 0px;
        bottom: 0;
        left: 0;
        z-index: 1000;
        display: block;
        padding: 0 20px 20px 20px;
        overflow-x: hidden;
        overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
        background-color: lighten($brand-primary, 0%); //#f5f5f5;
        border-right: 1px solid #eee;
      }
    }

    .nav > li > a:hover, .nav > li > a:focus {
    	background: darken($brand-primary, 5%);
    }

    /* Sidebar navigation */
    .nav-sidebar {
      margin-right: -21px; /* 20px padding + 1px border */
      margin-bottom: 20px;
      margin-left: -20px;
      margin-top: 20px;
    }
    .nav-sidebar > li > a {
      color: #fff;
      padding-right: 20px;
      padding-left: 20px;
    }
    .nav-sidebar > .active > a,
    .nav-sidebar > .active > a:hover,
    .nav-sidebar > .active > a:focus {
      color: #fff;
      background-color: darken($brand-primary, 5%);
    }


    /*
     * Main content
     */

    .main {
      padding: 20px;
      position: absolute;
      top: 0px;
      bottom: 0px;
      overflow-x: hidden;
      overflow-y: auto;
      .jumbotron {
        background: #FFF;
        margin-top: 20px;
      }
    }
    @media (min-width: 769px) {
      .main {
        padding-right: 40px;
        padding-left: 40px;
      }
    }
    .main .page-header {
      margin-top: 0;
    }


    /*
     * Placeholder dashboard ideas
     */

    .placeholders {
      margin-bottom: 30px;
      text-align: center;
    }
    .placeholders h4 {
      margin-bottom: 0;
    }
    .placeholder {
      margin-bottom: 20px;
    }
    .placeholder img {
      display: inline-block;
      border-radius: 50%;
    }


    .bg-fade {
      font-size: 500px;
      color: rgba(0,0,0,0.1);
      position: absolute;
      right: -100px;
      top: 200px;
    }

}
